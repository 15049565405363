import logo from './logo.svg';
import './App.css';

function App() {
  return (
   
    <div className="App">
       <section class="banner" role="banner" id="home">
       <header id="header">
    <div class="header-content clearfix"> <a class="logo" href="index.html">Elegant <span>Brows</span></a>
      <nav class="navigation" role="navigation">
        <ul class="primary-nav">
     <li><a href="#home">Home</a></li>
     <li><a href="https://book.appt.cm/#/business/2640093a-9e33-4f3c-9917-e137ce083cb8/embed/view/task?gzsrc=fb">Book an appointment</a></li>
        </ul>
      </nav>
      <a href="#" class="nav-toggle">Menu<span></span></a> </div>
  </header>
  <div id="first-slider">
    <div id="carousel-example-generic" class="carousel slide carousel-fade">
        <ol class="carousel-indicators">
            <li data-target="#carousel-example-generic" data-slide-to="0" class="active"></li>
            <li data-target="#carousel-example-generic" data-slide-to="1"></li>
            <li data-target="#carousel-example-generic" data-slide-to="2"></li>
            <li data-target="#carousel-example-generic" data-slide-to="3"></li>
        </ol>
        <div class="carousel-inner" role="listbox">
            <div class="item active slide1">
                <div class="row"><div class="container"> 
                    <div class="col-md-9 text-left">
                     </div>
                </div></div>
             </div> 
            <div class="item slide2">
                <div class="row"><div class="container">
                    <div class="col-md-7 text-left">
                     </div> 
                </div></div>
            </div>
            <div class="item slide3">
                <div class="row"><div class="container">
                    <div class="col-md-7 text-left">
                     </div>  
                </div></div>
            </div>     
        </div>
        <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
            <i class="fa fa-angle-left"></i><span class="sr-only">Previous</span>
        </a>
        <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
            <i class="fa fa-angle-right"></i><span class="sr-only">Next</span>
        </a>
    </div>
</div>
</section>
<section id="intro" class="section intro">
  <div class="container">
    <div class="col-md-10 col-md-offset-1 text-center">
      <h3>Welcome To Elegant Brows</h3>
    </div>
  </div>
</section>
    </div>
    
  );
}

export default App;
